import _ from "lodash";
import Repository from "./Repository";
import createRequestOption from "./RequestParamUtil";
import TempRepository from "./TempRepository";
const linkRequestSSO = '/dokters/request-link-user'
function errorHandler(error) {
    let errorM = `Error`;
    let statusErr = "";
    if (error.response) {
        if (error.response.data) {
            if (error.response.data.error) {
                errorM = error.response.data.error;
            } else if (error.response.data.message) {
                errorM = !_.isEmpty(error.response.data.message)
                    ? error.response.data.message
                    : "An Unknown Error Has Occured";
            }
        }
        if (error.response.status) {
            statusErr = error.response.status;
        }
    }
    return {
        error: errorM,
        status: statusErr,
        message: error.message
    };
}
const defaultErrorMessage = `An Unknown Error Has Occured`;

export default {
    mapperJam ( objItem) {
        return {
            ...objItem,
            id: objItem.ID,
            statusJadwalDokters: [],
            jamMulai: objItem.jamMulai ? objItem.jamMulai.slice(0, 5): '',
            jamSelesai: objItem.jamSelesai ? objItem.jamSelesai.slice(0, 5) : ''
        }
    },
    groupDay(listJadwal, date) {
        if(listJadwal && listJadwal.length > 0) {
            const dataGroup = listJadwal.reduce((prev, item) => {
                const inArray = prev.findIndex(_item => typeof _item.hari == "number" && _item.hari == item.hari)
                const startJadwal = item.tanggalMulai ? item.tanggalMulai.slice(0, 10) : MaxMinDate.MIN_DATE
                const endJadwal = item.tanggalSelesai ? item.tanggalSelesai.slice(0, 10) : MaxMinDate.MAX_DATE
                const isValid = (moment(date).isBetween(startJadwal, endJadwal, null, '[]'))
                if(!isValid) return prev
                if(inArray < 0 ) {
                    const day = {
                        hari: item.hari,
                        jam: [this.mapperJam(item)]
                    }
                    prev.push(day)
                } else {
                    prev[inArray].jam.push(this.mapperJam(item))
                }
                return prev
            }, []).map(_item => {
                return {
                    jam: _item.jam.sort((a, b)=> a.jamMulai.localeCompare(b.jamMulai)),
                    hari: _item.hari == 6 ?  DayNameEnum.DAY[0] : DayNameEnum.DAY[_item.hari + 1] 
                }
            })
            return dataGroup
        }
        return []
    },
    mapperDataCallerDokter (list, date) {
        return list.map(_item => {
            const jadwals = this.groupDay(_item.jadwals, date)
            return {
                ..._item,
                id: _item.ID,
                jadwals
            }
        })
    },
    async postLinkRequestSSO(id, tenant_id) {
        try {
            const allIdp = JSON.parse(localStorage.idpHint)
            const idpHintObj = allIdp.find(_item => _item.tenant_id == tenant_id)
            const idpHint = idpHintObj ? idpHintObj.alias : ''
            const resp = await Repository({
                url: `${linkRequestSSO}/${id}?idpHint=${idpHint}`,
                method: "post"
            });
            if (resp.status == 200) {
                if (!resp.data) {
                    throw new Error(`request Failed`);
                }
                return resp;
            }
            throw resp;
        } catch(err) {
            return errorHandler(err, defaultErrorMessage);
        }
    },
    async getAllDoctorList(data = [],page = 1,date, resolve, reject) {
        let params = new URLSearchParams();
        params.append('page', page)
        params.append('page_size', 20)
        params.append('sort', 'asc')
        params.append('tanggalMulai', date)
        params.append('tanggalSelesai', date)
        const resp = await this.getDoctorDataList(
            {params}
        );
        let totalItem = 0
        if(resp.headers && resp.headers['x-count']) {
            totalItem = resp.headers['x-count']
        }
        data.push(...resp.data)
        if(data.length < totalItem) {
            this.getAllDoctorList(data, page + 1,date, resolve, reject)
        } else {
            resolve({data: this.mapperDataCallerDokter(data, date)})
        }
    },
    async getDoctorDataList({ params }) {
        try {
            const Rng = Math.random() * 10;
            // const resp = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (Rng > 0) {
            //             resolve(dummyData);
            //         } else {
            //             reject({
            //                 data: null,
            //                 statis: 500
            //             });
            //         }
            //     }, 2000);
            // });
            const resp = await TempRepository({
                url: `/dokters`,
                method: "get",
                params: params
            });
            if (resp.status == 200) {
                if (!resp.data) {
                    throw new Error(`getDoctorDataList Error -`);
                }
                return resp;
            }
            throw resp;
        } catch (err) {
            return errorHandler(err, defaultErrorMessage);
        }
    },
    async getDoctorSearch(params) {
        try {
            // const Rng = Math.random() * 10;
            // const resp = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (Rng > 0) {
            //             resolve(dummyData);
            //         } else {
            //             reject({
            //                 data: null,
            //                 statis: 500
            //             });
            //         }
            //     }, 2000);
            // });
            const resp = await TempRepository({
                url: `/dokters/_search`,
                method: "get",
                params: params
            });
            if (resp.status == 200) {
                if (!resp.data) {
                    throw new Error(`getDoctorDataList Error -`);
                }
                return resp;
            }
            throw resp;
        } catch (err) {
            return errorHandler(err, defaultErrorMessage);
        }
    },
    async getDoctorDataDropdown(params) {
        try {
            const resp = await TempRepository({
                url: `/dokters/dropdown`,
                method: "get",
                params: params
            });
            if (resp.status == 200) {
                if (!resp.data) {
                    throw new Error(`getDoctorDataDropdown Error -`);
                }
                return resp;
            }
            throw resp;
        } catch (err) {
            return errorHandler(err, defaultErrorMessage);
        }
    },
    async getKlinikDataDropDown(params) {
        try {
            // const Rng = Math.random() * 10;
            // let params = {
            //     page_size: 1000
            // };
            const resp = await TempRepository({
                url: `/kliniks`,
                method: "get",
                params: params
            });
            if (resp.status == 200) {
                if (!resp.data) {
                    throw new Error(`getKlinikDataDropDown Error -`);
                }
                return resp;
            }
            throw resp;
        } catch (err) {
            return errorHandler(err, defaultErrorMessage);
        }
    },
    async getSpesialisasiDataDropDown(params) {
        try {
            // let params = {
            //     page_size: 1000
            // };
            const resp = await TempRepository({
                url: `/spesialisasis`,
                method: "get",
                params: params
            });
            if (resp.status == 200) {
                if (!resp.data) {
                    throw new Error(`getSpesialisasiDataDropDown Error -`);
                }
                return resp;
            }
            throw resp;
        } catch (err) {
            return errorHandler(err, defaultErrorMessage);
        }
    },
    async getSubSpesialisasiDataDropDown(params) {
        try {
            // let params = {
            //     page_size: 1000
            // };
            const resp = await TempRepository({
                url: `/subspesialisasis`,
                method: "get",
                params: params
            });
            if (resp.status == 200) {
                if (!resp.data) {
                    throw new Error(`getSubSpesialisasiDataDropDown Error -`);
                }
                return resp;
            }
            throw resp;
        } catch (err) {
            return errorHandler(err, defaultErrorMessage);
        }
    },
    async getTarifTelekonsultasiDataDropDown(params) {
        try {
            // let params = {
            //     page_size: 1000
            // };
            const resp = await TempRepository({
                url: `/tarifs`,
                method: "get",
                params: params
            });
            if (resp.status == 200) {
                if (!resp.data) {
                    throw new Error(
                        `getTarifTelekonsultasiDataDropDown Error -`
                    );
                }
                return resp;
            }
            throw resp;
        } catch (err) {
            return errorHandler(err, defaultErrorMessage);
        }
    },
    async postAddDoctorData(payload) {
        try {
            // const Rng = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (Rng > 0) {
            //             resolve({
            //                 status: 200,
            //                 data: "success Menambah Data Doctor"
            //             });
            //         } else {
            //             reject({ status: 500, message: "Error" });
            //         }
            //     }, 2000);
            // });
            const response = await TempRepository({
                url: `/dokter`,
                method: "post",
                data: payload
            });
            if (
                response.data &&
                (response.status == 200 || response.status == 201)
            ) {
                return response.data;
            }
            throw response;
        } catch (error) {
            console.log(error, "post Data Doctor");
            return errorHandler(error, defaultErrorMessage);
        }
    },
    async putEditDoctorData(payload) {
        try {
            const response = await TempRepository({
                url: `/dokter`,
                method: "put",
                data: payload
            });
            if (response.status == 200) {
                return response;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "put Data Doctor");
            return errorHandler(error, defaultErrorMessage);
        }
    },
    async getOneDoctorData(id) {
        try {
            const response = await TempRepository({
                url: `/dokter/${id}`,
                method: "get"
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "get Data Doctor");
            return errorHandler(error, defaultErrorMessage);
        }
    },
    async reIndexData() {
        try {
            const response = await TempRepository({
                url: `/re-index-dokter`,
                method: "get"
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "get reindex Doctor");
            return errorHandler(error, defaultErrorMessage);
        }
    },
    async delDataDoctor(id) {
        try {
            const response = await TempRepository({
                url: `/dokter/${id}`,
                method: "delete"
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "delete Data Doctor");
            return errorHandler(error, defaultErrorMessage);
        }
    },
    async postJadwalDokter(payload) {
        try {
            const response = await TempRepository({
                url: `/jadwal`,
                method: "post",
                data: payload
            });
            if (response.status == 201) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "post Data Doctor");
            return errorHandler(error, defaultErrorMessage);
        }
    },
    async putJadwalDokter(payload) {
        try {
            const response = await TempRepository({
                url: `/jadwal`,
                method: "put",
                data: payload
            });
            if (response.status == 200 || response.status == 201) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "delete Data Doctor");
            return errorHandler(error, defaultErrorMessage);
        }
    },
    async getOneJadwalDokter(id) {
        try {
            const response = await TempRepository({
                url: `/jadwal/${id}`,
                method: "get"
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "get Data Doctor");
            return errorHandler(error, defaultErrorMessage);
        }
    },
    async deleteJadwalDokter(id) {
        try {
            const response = await TempRepository({
                url: `/jadwal/${id}`,
                method: "delete"
            });
            if (response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "del jadwal Doctor");
            return errorHandler(error, defaultErrorMessage);
        }
    },
    async postAbsenceData(payload) {
        try {
            const response = await TempRepository({
                url: `/status-jadwal`,
                method: "post",
                data: payload
            });
            if (response.status == 201) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "error postAbsenceData");
            return errorHandler(error, defaultErrorMessage);
        }
    },
    async postKetidakhadiran(payload) {
        try {
            const response = await TempRepository({
                method: "post",
                url: `/status-jadwal`,
                data: payload
            });
            if (response.status == 201) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "post Ketidakhadiran");
            return errorHandler(error);
        }
    },
    async postProfileAndCss(payload) {
        try {
            // console.log(payload, "payload 414");
            // const Rng = Math.random() * 10;
            // const response = await new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         if (Rng > 0) {
            //             resolve({
            //                 status: 201,
            //                 data: "success Menambah Data Doctor"
            //             });
            //         } else {
            //             reject({ status: 500, message: "Error" });
            //         }
            //     }, 2000);
            // });
            const response = await TempRepository({
                method: "post",
                url: `/dokter/profile`,
                data: payload
            });
            if (response.status == 201 || response.status == 200) {
                return response.data;
            } else {
                throw response;
            }
        } catch (error) {
            console.log(error, "post postProfileAndCss");
            return errorHandler(error);
        }
    }
};
